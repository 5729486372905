import React from "react"
import { Link } from "gatsby"
import Products from "../../components/peaksuiteProducts"

const PeaksuiteDocGeneratorSection = ({ handleSubmit }) => (
  <div className="page-content pt-0" style={{ background: "#f8f8f8" }}>
    <section className="component pt-4 pb-0">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12 peakSuite-product">
            <div className="row">
              <div className="col-md-7 col-sm-12">
                <iframe
                  src="https://drive.google.com/file/d/1wZ5dSTcy-e6Ql7c--oaOxESuBgKNf1xS/preview"
                  frameBorder="0"
                  width="100%"
                  height="400"
                  className="mr-3 mb-3"
                  style={{ boxShadow: "0 8px 10px rgba(0, 0, 0, 0.37)" }}
                  title="basecamp"
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <p>
                  <span className="basecamp-color">
                    peakSUITE | Document Generator Tool{" "}
                  </span>
                  Easily transfer data from any system or platform into a base
                  template before packaging it into a completed file (such as a
                  .doc or .pdf) and sending it to storage or email. It’s the
                  easiest way to send documents to those who need them. Quick.
                  Accessible. Effortless
                </p>
              </div>
            </div>
            {/* <div className="list-wrap d-block">
                    <p>What can this application do?</p>
                    <ul className="list-unstyled basecamp">
                      <li>Reporting and notifications for managers</li>
                      <li>Track tasks and who they are assigned to</li>
                      <li>
                        Easily see which tasks are complete and which tasks are
                        outstanding
                      </li>
                      <li>
                        Allow employees to flag tasks that require assistance to
                        complete, or are blocked
                      </li>
                      <li>
                        Record meeting notes in one place for easy reference
                      </li>
                      <li>
                        Implement daily summary notes for employees to document
                        their productivity
                      </li>
                      <li>
                        Maintain a list of your employees, their contact
                        information, and the company inventory they are using at
                        their home office
                      </li>
                      <li>
                        Provide employees with a ‘who to call’ list so they will
                        know who to reach out to if they have certain issues
                      </li>
                      <li>
                        Keep employees up to date with company news and
                        announcements with peakSUITE | Echo, a tool for company
                        announcements built right into their dashboard
                      </li>
                      <li>
                        Maintain a list of your clients and their contact
                        information if desired
                      </li>
                      <li>Store documents for easy reference</li>
                    </ul>
                  </div> */}

            {/* <p>
                    Managers see a real time overview of work in progress,
                    complete, and outstanding on their dashboard. Stay connected
                    and understand what your employees are working on and where
                    they need help. Fully customizable to meet your
                    company&#39;s specific needs in this rapidly changing time.
                  </p> */}
            <div className="text-center">
              <Link
                to="/contact/"
                onClick={handleSubmit}
                className="btn btn-primary btn-arrow echo-bg"
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
        <div className="row peaksuite-products mt-5">
          <div className="col-lg-2 col-md-3 col-12 align-self-center  prod-order-2">
            {" "}
            <div className="return-btn-basecamp">
              <Link
                to="/peaksuite/"
                className="btn btn-primary-outline px-3"
                style={{
                  color: "#224a9a",
                  border: "1px solid #224a9a",
                  fontSize: "18px",
                }}
              >
                <i className="fa fa-caret-left mr-2" />
                Return
              </Link>
            </div>
          </div>
          <div className="col-lg-10 col-md-9 col-12 prod-order-1">
            <Products />
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default PeaksuiteDocGeneratorSection
