import React from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

class Products extends React.Component {
  render() {
    return (
      <div className="product-peaksuite-list my-5" id="product-peaksuite-list">
        <div className="container">
          <div className="row row-cols-lg-5">
            <div className="col-6 col-md-4 col-lg list-of-peaksuiteProduct pl-lg-0">
              <Link
                aria-label="link"
                to="/peaksuite/peaksuite-compass/"
                className="my-0"
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="rounded peaksuite-deploying-img"
                  src="../../../static/assets/productLogos/PS_compass.png"
                  alt="peakSUITE Compass"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="col-6 col-md-4 col-lg list-of-peaksuiteProduct pl-lg-0">
              <Link
                to="/peaksuite/peaksuite-docgenerator"
                className="align-self-center my-0"
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="rounded peaksuite-deploying-img"
                  src="../../../static/assets/productLogos/peaksuite-DU.png"
                  alt="peakSUITE Document Generator"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </div>
            {/* <div className="col-6 col-md-4 col-lg list-of-peaksuiteProduct pl-lg-0">
              <Link
                aria-label="link"
                to="/peaksuite/peaksuite-echo/"
                className="my-0"
                style={{ borderRight: "1px solid #1eb6bb" }}
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="rounded peaksuite-deploying-img"
                  src="../../../static/assets/productLogos/PS-echo.png"
                  alt="peakSUITE Echo"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </div> */}
            {/* <div className="col-6 col-md-4 col-lg list-of-peaksuiteProduct pl-lg-0">
              <Link
                aria-label="link"
                to="/peaksuite/peaksuite-trailhead/"
                className="my-0"
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="rounded peaksuite-deploying-img"
                  src="../../../static/assets/productLogos/PS_trailhead.png"
                  alt="peakSUITE Trailhead"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </div> */}

            {/* <div className="col-6 col-md-4 col-lg list-of-peaksuiteProduct pl-lg-0">
              <Link
                to="/peaksuite/peaksuite-basecamp/"
                aria-label="link"
                className="align-self-center my-0"
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="rounded peaksuite-deploying-img"
                  src="../../../static/assets/productLogos/basecamp.png"
                  alt="peakSUITE Basecamp"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </div> */}
            <div className="col-6 col-md-4 col-lg list-of-peaksuiteProduct pl-lg-0">
              <Link
                to="/peaksuite/peaksuite-multi-file-uploader/"
                className="align-self-center my-0"
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="rounded peaksuite-deploying-img"
                  src="../../../static/assets/productLogos/PS_multi-file-uploader.png"
                  alt="peakSUITE Multi File Uploader"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </div>

            {/* <div className="col-6 col-md-4 col-lg list-of-peaksuiteProduct pl-lg-0">
              <Link
                to="/peaksuite/peaksuite-pivot-table/"
                className="align-self-center my-0"
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="rounded peaksuite-deploying-img"
                  src="../../../static/assets/PS-Pivot-Table.png"
                  alt="peakSUITE Pivot Table"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </div> */}
            {/* <div className="col-6 col-md-4 col-lg list-of-peaksuiteProduct pl-lg-0">
              <Link
                to="/peaksuite/peaksuite-chat-tool/"
                className="align-self-center my-0"
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="rounded peaksuite-deploying-img"
                  src="../../../static/assets/peaksuite-Chat Tool.png"
                  alt="peakSUITE Chat Tool"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </div> */}
            {/* <div className="col-6 col-md-4 col-lg list-of-peaksuiteProduct pl-lg-0">
              <Link
                to="/peaksuite/peaksuite-calendar/"
                className="align-self-center my-0"
              >
                <StaticImage
                  placeholder="blurred"
                  imgClassName="rounded peaksuite-deploying-img"
                  src="../../../static/assets/peaksuite-Calendar.png"
                  alt="peakSUITE Calendar"
                  layout="constrained"
                  loading="lazy"
                />
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default Products
